// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {

  font-family: 'Lato', sans-serif;}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;;EAEE,+BAA+B,CAAC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');\n\nbody {\n\n  font-family: 'Lato', sans-serif;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
